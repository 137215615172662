// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assist/images/home/plant4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-hero {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.contact-container {
    width: 100%;
    max-width: 900px;
    margin: auto;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-container form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}


.contact-container button {
    border: none;
    align-self: flex-start;
    margin: 0;
    font-size: 16px;

}`, "",{"version":3,"sources":["webpack://./src/components/contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,yDAA4D;AAChE;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,SAAS;IACT,eAAe;;AAEnB","sourcesContent":[".contact-hero {\n    background-image: url('../../assist/images/home/plant4.jpg');\n}\n\n.contact-container {\n    width: 100%;\n    max-width: 900px;\n    margin: auto;\n}\n\n.contact-container h2 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.contact-container form {\n    display: flex;\n    flex-direction: column;\n    gap: 1.5em;\n}\n\n\n.contact-container button {\n    border: none;\n    align-self: flex-start;\n    margin: 0;\n    font-size: 16px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
