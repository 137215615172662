import React from 'react';
import { IoMdClose } from "react-icons/io";

const CloseIcon = () => {
  return (
    <IoMdClose className="close-icon" />
  );
};

export default CloseIcon;
