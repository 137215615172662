import React, { useState } from "react";
import "./CaseStudies.css";
import {
  MdDescription,
  MdOutlineReportProblem,
  MdAutoFixHigh,
  MdCloudDone,
} from "react-icons/md";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import {
  plantDesignOne,
  plantDesignTwo,
  plantDesignThree,
  plantDesignFour,
} from "../../assist/index";
import { IoMdClose } from "react-icons/io";
import pageTransition from "../../variants/pageTransition";

const designCaseStudies = [
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis. Suspendisse convallis quam eget enim fermentum, vitae suscipit dui convallis.",
    challenge:
      "Phasellus sed dolor vitae turpis congue molestie. Donec eu risus eu orci semper ullamcorper. Phasellus et orci eget lacus vulputate mollis. Integer egestas interdum massa. Aliquam ac felis ultricies, vestibulum mauris a, ornare nisl. Sed sit amet nisl a orci rutrum efficitur.",
    solution:
      "Praesent dapibus enim quis nunc fringilla, vitae gravida arcu aliquam. Sed finibus pulvinar urna, at commodo dolor sodales. Maecenas sed est efficitur, hendrerit risus ac, pretium ante. Vivamus erat nulla, dictum ut ante quis.",
    outcome:
      "Duis diam diam, consequat et nibh in, tempus lacinia lacus. Nulla facilisi. Mauris fermentum, felis vehicula congue pellentesque, tellus sapien pulvinar nunc, sit amet maximus sapien augue eu lectus. Duis a nulla metus. Ut vitae ligula nisl. Donec sed dignissim ligula.",
  },
];

const CustomPlantDesign = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    imgSrc: "",
    animation: "",
  });

  const openModal = (imgSrc) => {
    setModal({ isOpen: true, imgSrc, animation: "fade-in" });
  };

  const closeModal = () => {
    setModal((prev) => ({ ...prev, animation: "fade-out" }));
    setTimeout(() => {
      setModal({ isOpen: false, imgSrc: "", animation: "" });
    }, 300); // Match the CSS animation duration
  };

  return (
    <section>
      <Helmet>
        <title>Case Study | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero case-hero-image">
          <div className="case-hero">
            <h1>Case Study</h1>
          </div>
        </div>
      </div>
      <div className="case-images container pb-0">
        <div className="image-wrapper">
          <img
            src={plantDesignOne}
            alt="Description"
            onClick={() => openModal(plantDesignOne)}
          />
        </div>
        <div className="image-wrapper">
          <img
            src={plantDesignTwo}
            alt="Challenge"
            onClick={() => openModal(plantDesignTwo)}
          />
        </div>
        <div className="image-wrapper">
          <img
            src={plantDesignThree}
            alt="Solution"
            onClick={() => openModal(plantDesignThree)}
          />
        </div>
        <div className="image-wrapper">
          <img
            src={plantDesignFour}
            alt="Outcome"
            onClick={() => openModal(plantDesignFour)}
          />
        </div>
      </div>
      {modal.isOpen && (
        <div
          className={`modal ${modal.animation}`}
          onClick={closeModal} // Use the shared closeModal function
        >
          <div
            className="modal-content-wrapper"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                <IoMdClose />
              </span>
              <img src={modal.imgSrc} alt="Enlarged view" />
            </div>
          </div>
        </div>
      )}
      <div className="card-container container">
        {designCaseStudies.map((study, index) => (
          <div key={index} className="card">
            <div className="card-item">
              <MdDescription className="icon" />
              <h2>Description</h2>
              <p>{study.description}</p>
            </div>
            <div className="card-item">
              <MdOutlineReportProblem className="icon" />
              <h2>Challenge</h2>
              <p>{study.challenge}</p>
            </div>
            <div className="card-item">
              <MdAutoFixHigh className="icon" />
              <h2>Solution</h2>
              <p>{study.solution}</p>
            </div>
            <div className="card-item">
              <MdCloudDone className="icon" />
              <h2>Outcome</h2>
              <p>{study.outcome}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default pageTransition(CustomPlantDesign);
