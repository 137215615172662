import React, { useState, useEffect, useRef } from "react";
import { FaSquareFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { FaToggleOn } from "react-icons/fa";
import { IoMdMenu, IoMdClose, IoIosArrowDown } from "react-icons/io";
import "./Navbar.css";
import { navHoverImg } from "../../assist/index";
import { Link } from "react-router-dom";
import MenuIcon from "../../variants/MenuIcon";
import CloseIcon from "../../variants/CloseIcon";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [subDropdown, setSubDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const megaMenuRef = useRef(null);
  const navbarRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const megaMenuContainerRef = useRef(null);
  const navLinksRef = useRef(null);

  const toggleMenu = () => {
    setIsActive((prev) => !prev);
  };

  const handleClick = (dropdownSetter) => {
    setActiveDropdown((prevDropdown) =>
      prevDropdown === dropdownSetter ? null : dropdownSetter
    );
  };

  const handleSubClick = (subDropdownSetter) => {
    setSubDropdown((prevSubDropdown) =>
      prevSubDropdown === subDropdownSetter ? null : subDropdownSetter
    );
  };

  const handleClickOutside = (event) => {
    const clickedInsideDropdown =
      dropdownContainerRef.current && dropdownContainerRef.current.contains(event.target);
  
    const clickedInsideMegaMenu =
      megaMenuContainerRef.current && megaMenuContainerRef.current.contains(event.target);
  
    const clickedInsideNavLinks =
      navLinksRef.current && navLinksRef.current.contains(event.target);
  
    // If the click is not inside dropdown, mega menu, or nav links container, then close
    if (!clickedInsideDropdown && !clickedInsideMegaMenu && !clickedInsideNavLinks) {
      setIsActive(false);
      setActiveDropdown(null);
      setSubDropdown(null);
    }
  };  
  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const toggleTheme = () => {
      const body = document.body;
      const currentTheme = body.getAttribute("data-theme");
      const newTheme = currentTheme === "dark" ? "light" : "dark";
      body.setAttribute("data-theme", newTheme);
      localStorage.setItem("theme", newTheme);
    };

    window.toggleTheme = toggleTheme;

    const savedTheme = localStorage.getItem("theme") || "light";
    document.body.setAttribute("data-theme", savedTheme);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      console.log("Resize event handled, no max-height dynamically applied.");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="header-content">
      <nav className="navbar" aria-label="Main Navigation">
        <div className="logo">
          <a href="/">CICELY</a>
          <button
            className="theme-toggle view-992-up"
            onClick={() => window.toggleTheme()}
          >
            <FaToggleOn />
          </button>
        </div>
        <div className="navbar-right">
          <div className={`nav-upper ${isActive ? "active" : ""}`}>
            <div className="nav-socials">
              <Link
                to="https://www.facebook.com/"
                target="_blank"
                reloadDocument
              >
                <FaSquareFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/"
                target="_blank"
                reloadDocument
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://web.whatsapp.com/"
                target="_blank"
                reloadDocument
              >
                <FaWhatsapp />
              </Link>
            </div>
          </div>
          <div className={`nav-lower ${isActive ? "active" : ""}`}>
            <ul
              className={`nav-links ${isActive ? "show" : "hide"}`}
              ref={navLinksRef}
            >
              <li className="close-mobile">
                <div className="close-icon-item" onClick={toggleMenu}>
                  {isActive && <CloseIcon />}
                </div>
              </li>
              <li>
                <Link to="/plant-directory" reloadDocument>
                  Plants Directory
                </Link>
              </li>

              {/* Case Studies Dropdown */}
              <li
                className={`nav-dropdown ${
                  activeDropdown === "caseStudies" ? "active" : ""
                }`}
              >
                <Link
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from bubbling up
                    handleClick("caseStudies");
                  }}
                >
                  Case Studies
                  <IoIosArrowDown
                          className={`arrow-icon ${
                            activeDropdown === "caseStudies" ? "rotate" : ""
                          }`}
                        />
                </Link>
                <div
                  ref={dropdownContainerRef}
                  className={`dropdown-content ${
                    activeDropdown === "caseStudies" ? "active" : ""
                  }`}
                  onClick={(e) => e.stopPropagation()} // Prevent clicks inside from bubbling up
                >
                  <ul className="nav-dropdown">
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubClick("allCaseStudies");
                      }}
                    >
                      <Link>
                        Project Highlights
                        <IoIosArrowDown
                          className={`arrow-icon ${
                            subDropdown === "allCaseStudies" ? "rotate" : ""
                          }`}
                        />
                      </Link>
                      <ul
                        className={`sub-dropdown-content ${
                          subDropdown === "allCaseStudies" ? "active" : ""
                        }`}
                      >
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study One
                          </Link>
                        </li>
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study Two
                          </Link>
                        </li>
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study Three
                          </Link>
                        </li>
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study Four
                          </Link>
                        </li>
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study Five
                          </Link>
                        </li>
                        <li>
                          <Link to="/case-study" reloadDocument>
                            Case Study Six
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/about" reloadDocument>
                        About Cicely
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link
                        className="button"
                        to="/all-case-studies"
                        reloadDocument
                      >
                        All Case Studies
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Services Dropdown */}
              <li className={`nav-dropdown megamenu ${activeDropdown === "services" ? "active" : ""}`}>
                <Link onClick={(e) => { e.stopPropagation(); handleClick("services"); }}>
                  Services
                  <IoIosArrowDown
                          className={`arrow-icon ${
                            activeDropdown === "services" ? "rotate" : ""
                          }`}
                        />
                </Link>
                <div
                  className={`submenu-container ${activeDropdown === "services" ? "active" : ""}`}
                  ref={megaMenuContainerRef}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="hover-image">
                    <img src={navHoverImg} alt="Special Offer" />
                  </div>
                  <ul className="hover-links">
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle One
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle Two
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle Three
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle Four
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle Five
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" reloadDocument>
                        Service Bundle Six
                      </Link>
                    </li>
                    <li className="hover-links-last-item">
                      <Link
                        className="button"
                        to="/all-services"
                        reloadDocument
                      >
                        All Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <Link to="/blog" reloadDocument>
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" reloadDocument>
                  Contact
                </Link>
              </li>
            </ul>

            <div className="toggleMenu" onClick={toggleMenu}>
              {!isActive && <MenuIcon />}
            </div>
          </div>
        </div>
      </nav>
      <button
        className="theme-toggle view-991-down"
        onClick={() => window.toggleTheme()}
      >
        <FaToggleOn />
      </button>
    </div>
  );
};

export default Navbar;
