import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import { MdLabelImportant } from "react-icons/md";
import { plantMaintSlideOne, plantMaintSlideTwo } from "../../assist/index";
import "./Services.css";
import pageTransition from "../../variants/pageTransition";


const PlantMaint = () => {
  return (
    <div>
      <Helmet>
        <title>Service Bundle | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero service-hero">
          <div className="hero-content">
            <h1>Service Bundle</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="service-details-layout">
          <div className="service-text">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis. Phasellus sed dolor vitae turpis congue molestie. Donec eu risus eu orci semper ullamcorper. Phasellus et orci eget lacus vulputate mollis. Praesent dapibus enim quis nunc fringilla, vitae gravida arcu aliquam. Sed finibus pulvinar urna, at commodo dolor sodales at. 
                </p>
                <p>
                    Suspendisse convallis quam eget enim fermentum, vitae suscipit dui convallis. Integer egestas interdum massa. Aliquam ac felis ultricies, vestibulum mauris a, ornare nisl. Sed sit amet nisl a orci rutrum efficitur. Maecenas velit ex, pretium eu pretium ac, rhoncus quis nibh. Aenean auctor ipsum quis dui lacinia, quis molestie leo fermentum. Nam eleifend varius sodales. Ut consectetur sed leo nec pretium.
                </p>
                <p>
                    Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget, tincidunt nibh. Pellentesque facilisis luctus nunc vitae egestas. Nullam accumsan felis eu ultrices consectetur. 
                </p>
                <h2>Our Capabilities</h2>
                <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Mauris id leo ut arcu faucibus dapibus at eu mauris.</li>
                    <li>Maecenas vulputate turpis ac lacus gravida, et dapibus augue pretium.</li>
                    <li>Suspendisse efficitur mauris eget ante placerat, sit amet mollis nulla vulputate.</li>
                </ul>
                <h2>Recognition</h2>
                <p>
                    Morbi gravida odio et odio euismod fringilla. Phasellus lacus sem, aliquam quis magna eget, faucibus mollis nunc. Etiam ultricies magna sed mi aliquet placerat sit amet sit amet mi. Fusce blandit, ante vel accumsan aliquam, risus mi viverra libero, sit amet placerat mi diam ac nibh. 
                </p>
                <p>
                    Nam sapien nulla, interdum et lectus a, congue cursus tortor. Donec semper mattis dolor, rutrum laoreet augue auctor id. Mauris tincidunt nunc quis orci viverra tristique. Integer commodo ipsum eget ex vehicula, et convallis leo tristique. Aliquam ut malesuada nunc.
                </p>            
                <h2>What We Offer</h2>
                <h3>Interdum auctor efficitur </h3>
                <p>
                    Magna eget nascetur nunc egestas lobortis dolor. Imperdiet nam ante molestie eros lacinia dignissim fermentum varius inceptos. Pharetra ut ad purus laoreet semper.
                </p>
                <h3>Nam inceptos ac vivamus </h3>
                <p>
                    Turpis penatibus hac nostra primis, purus libero nulla nostra mollis. Congue facilisi parturient arcu diam volutpat aptent integer. Diam urna nibh ut sed efficitur.
                </p>
                <h3>Gravida habitasse integer aptent </h3>
                <p>
                    Aliquam quisque porttitor tempor sapien, nisl sapien viverra. Accumsan blandit leo maximus viverra dis sociosqu ad primis placerat. Justo neque fringilla netus leo adipiscing magna.
                </p>
          </div>
          <div className="service-images">
            <img src={plantMaintSlideOne} alt="Eco-Friendly Pest Management" />
            <img src={plantMaintSlideTwo} alt="Advanced Irrigation Solutions" />
          </div>
        </div>
      </div>

      <section id="promotion">
        <div className="container">
          <div className="service promo-section">
            <div className="promo-content">
              <h2>Keep Your Garden Vibrant and Thriving!</h2>
              <h3>Contact us today!</h3>
              <Link className="button" to="/contact" reloadDocument>
                Talk to us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pageTransition(PlantMaint);
