import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdLabelImportant } from "react-icons/md";
import {
  plantInstallSlideOne,
  plantInstallSlideTwo 
} from '../../assist/index';
import './Services.css';
import pageTransition from '../../variants/pageTransition';

const service = {
  overview: "Transform your landscape with our Plant Installation services. Our expert team provides meticulous site assessment, soil preparation, and plant selection to ensure your outdoor spaces thrive. Tailored to both residential and commercial properties, our services are designed to enhance the aesthetic and environmental quality of your area.",
  capabilities: [
    "Comprehensive site assessment to determine the best plants and layout for your soil and microclimate.",
    "Detailed soil testing and conditioning to ensure the best possible start for your new plants.",
    "Access to a wide range of plant species selected based on local climate and soil conditions.",
    "Plants installed at the perfect depth and with optimal spacing, utilizing techniques that promote healthy root growth and plant stability.",
    "Installation of irrigation systems tailored to your landscape's specific needs."
  ],
  recognition: "Our Plant Installation services have been widely recognized for excellence in landscape enhancement. We have received the Green Thumb Award for three consecutive years and have been featured in 'Gardeners' World' and 'Outdoor Living Today'.",
  detailedServices: [
    {
      title: "Residential Garden Installation",
      description: "Specializing in creating stunning garden layouts that transform your home's outdoor spaces into a serene and inviting retreat."
    },
    {
      title: "Commercial Landscaping Projects",
      description: "Providing large-scale plant installations for businesses, enhancing curb appeal and environmental responsibility."
    },
    {
      title: "Specialized Plant Installations",
      description: "Offering unique installations such as rooftop gardens, edible landscapes, and drought-resistant plantings."
    }
  ],
};

const Services = () => {
  return (
    <div>
      <Helmet>
        <title>CICELY | Professional Plant Installation Services</title>
        <meta name="description" content="Discover our expert plant installation services designed to transform residential and commercial landscapes. Learn about our site assessment, soil preparation, and plant selection process." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="service-hero-image">
            <div className="service-hero">
                <h1>
                  Plant Installation
                </h1>
            </div>
        </div>
      </div>
      <div className="container">
        <div className="service-details-layout">
            <div className="service-text">
              <p>{service.overview}</p>
              <div>
                <h2>Our Capabilities</h2>
                <ul>
                  {service.capabilities.map((capability, index) => (
                    <li key={index}>{capability}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h2>Recognition</h2>
                <p>{service.recognition}</p>
              </div>
              <div>
                <h2>What We Offer</h2>
                {service.detailedServices.map((detail, index) => (
                  <article key={index}>
                    <h3><MdLabelImportant className='label'/>{detail.title}</h3>
                    <p>{detail.description}</p>
                  </article>
                ))}
              </div>
            </div>
            <div className="service-images">
              <img src={plantInstallSlideOne} alt="Residential Garden Installation" />
              <img src={plantInstallSlideTwo} alt="Commercial Landscaping Projects" />
            </div>
        </div>
      </div>
      <div>
        <div className="service-bottom-hero">
            <div className="service-bottom-hero-content">
                <h2>
                  Ready to enhance
                  your landscape? 
                </h2>
                <h3>Contact us today!</h3>
                <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
            </div>
        </div>
      </div>
      <hr/>
    </div>
  );
};

export default pageTransition(Services);
