// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assist/images/about/about-heroImg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-hero-image {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.about-feature:last-child {
    margin-bottom: 0;
}

.about-feature:nth-child(even) .about-feature-image {
    order: 2;
}

`, "",{"version":3,"sources":["webpack://./src/components/about/About.css"],"names":[],"mappings":"AAAA;IACI,yDAAoE;AACxE;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".about-hero-image {\n    background-image: url('../../assist/images/about/about-heroImg.jpg');\n}\n\n.about-feature:last-child {\n    margin-bottom: 0;\n}\n\n.about-feature:nth-child(even) .about-feature-image {\n    order: 2;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
