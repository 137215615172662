import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import pageTransition from "../../variants/pageTransition";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero contact-hero">
          <div className="contact-hero-content">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="contact-container container pb-0">
          <h2>SEND US AN EMAIL</h2>
          <form>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your full name"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  required
                />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your message"
                required
              ></textarea>
            </div>
            <button type="submit">SEND</button>
          </form>
        </div>
        <section className="pickup-info container">
          <div className="pickup-info-overlay">
            <h2>View Pickup and Delivery Options</h2>
            <p>M - F: 9:00am - 6:00pm</p>
            <Link className="button" to="/contact" reloadDocument>
              More Info
            </Link>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.456324194087!2d-79.39477718450073!3d43.66872927912124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34b0fcaa03b7%3A0xcc2b1658740928a2!2sToronto!5e0!3m2!1sen!2sca!4v1622484070764!5m2!1sen!2sca"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            aria-label="Location map"
            title="contact-map"
          ></iframe>
        </section>
      </div>
    </div>
  );
};

export default pageTransition(Contact);
