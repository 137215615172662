import React from "react";
import { Link } from "react-router-dom";
import { blogImgOne, blogImgTwo, blogImgThree } from "../../assist/index";

import { plantItemOne, plantItemTwo, plantItemThree } from "../../assist/index"; // Import product images

const blogPosts = [
  {
    img: blogImgOne,
    alt: "Wellness and Your Indoor Air Quality",
    date: "May 17, 2021",
    title: "Wellness and Your Indoor Air Quality",
    link: "/individual-blog",
  },
  {
    img: blogImgTwo,
    alt: "Simple Spring Projects",
    date: "April 05, 2021",
    title: "Simple Spring Projects",
    link: "/individual-blog",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z",
    link: "/individual-blog",
  },
];
const featuredProducts = [
  {
    img: plantItemOne,
    alt: "Succulent Collection – Low Maintenance Indoor Plants",
    title: "Succulent Collection – Low Maintenance Indoor Plants",
    link: "/individual-plant",
  },
  {
    img: plantItemTwo,
    alt: "Aloe Vera – Easy Care Plant with Soothing Benefits",
    title: "Aloe Vera – Easy Care Plant with Soothing Benefits",
    link: "/individual-plant",
  },
  {
    img: plantItemThree,
    alt: "Bonsai Tree – Miniature Art for Your Living Space",
    title: "Bonsai Tree – Miniature Art for Your Living Space",
    link: "/individual-plant",
  },
];

const BlogSidebar = () => (
  <div className="sidebar-right">
    {/* Contact Us Widget */}
    <div className="widget-contact-us">
      <h3>Need assistance? We're here to help!</h3>
      <Link className="button" to="/contact" reloadDocument>
        Contact Us
      </Link>
    </div>

    {/* Featured Posts */}
    <div className="widget featured-posts">
      <h2>Featured Posts</h2>
      <div className="post">
        {blogPosts.map((post, index) => (
          <Link key={index} className="post-item" to={post.link} reloadDocument>
            <img src={post.img} alt={post.alt} />
            <div className="post-content">
              <p>{post.date}</p>
              <h3>{post.title}</h3>
              <p className="read-more">Read More</p>
            </div>
          </Link>
        ))}
      </div>
    </div>

    {/* Featured Products */}
    <div className="widget featured-products">
      <h2>Featured Plants</h2>
      <div className="product">
        {featuredProducts.map((product, index) => (
          <Link
            key={index}
            className="product-item"
            to={product.link}
            reloadDocument
          >
            <img src={product.img} alt={product.alt} />
            <div className="product-content">
              <h3>{product.title}</h3>
              <p className="read-more">View Plant</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </div>
);

export default BlogSidebar;
