// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-content h1 {
    font-size: 40px;
    text-align: left;
    margin-bottom: 2rem;
    font-weight: 600;
    padding: 0
}

.privacy-content h2 {
    font-size: 22px;
    margin: 2em 0 1em;
    text-align: start;
    text-transform: none;
    font-family: var(--body-text);
}

.privacy-content p {
    font-size: 18px;
}

.privacy-content a {
    text-decoration: none;
}


.privacy-content span {
    text-transform: lowerse;
}

@media (max-width: 1200px) {

    .privacy-content h1,
    .privacy-content h2,
    .privacy-content p,
    .privacy-content {
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,uBAAuB;AAC3B;;AAEA;;IAEI;;;;QAII,kBAAkB;IACtB;AACJ","sourcesContent":[".privacy-content h1 {\n    font-size: 40px;\n    text-align: left;\n    margin-bottom: 2rem;\n    font-weight: 600;\n    padding: 0\n}\n\n.privacy-content h2 {\n    font-size: 22px;\n    margin: 2em 0 1em;\n    text-align: start;\n    text-transform: none;\n    font-family: var(--body-text);\n}\n\n.privacy-content p {\n    font-size: 18px;\n}\n\n.privacy-content a {\n    text-decoration: none;\n}\n\n\n.privacy-content span {\n    text-transform: lowerse;\n}\n\n@media (max-width: 1200px) {\n\n    .privacy-content h1,\n    .privacy-content h2,\n    .privacy-content p,\n    .privacy-content {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
