import React from 'react';
import { Helmet } from 'react-helmet';
import './IndividualBlog.css';
import pageTransition from '../../variants/pageTransition';
import { blogImgOne, blogImgTwo, blogImgThree, blogImgFour } from '../../assist/index';
import { Link } from 'react-router-dom';

const blogPosts = [
  {
    img: blogImgOne,
    alt: "Wellness and Your Indoor Air Quality",
    date: "May 17, 2021",
    title: "Wellness and Your Indoor Air Quality",
  },
  {
    img: blogImgTwo,
    alt: "Simple Spring Projects",
    date: "April 05, 2021",
    title: "Simple Spring Projects",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z",
  },
  {
    img: blogImgFour,
    alt: "Pet-Friendly Plants",
    date: "February 2, 2021",
    title: "Pet-Friendly Plants",
  },
];

const IndividualBlog = () => {
  return (
    <div>
        <Helmet>
            <title>CICELY | Individual Blog</title>
            <meta name="description" content="Discover a variety of plants and pots at the SWEET BAE shop. Whether you're looking for small succulents or large tropical plants, enjoy a 30% spring discount on all products!" />
        </Helmet>
        <p className="discount">30% off spring sale</p>
        <div>
          <div class="individual-blog-hero">
              <div class="individual-blog-hero-content">
                  <h1>
                      Individual Blog
                  </h1>
              </div>
          </div>
          <div className="container">
            <div className="content-wrapper">
              <div className="individual-blog-content">
                <article>
                  <h2>Simple Spring Projects: Enhance Your Space with Lush Greenery</h2>

                  <p>Spring is the perfect time to refresh your living space with vibrant and lush greenery. Simple spring projects, such as adding indoor plants, can transform your home, making it more inviting and aesthetically pleasing. In this blog post, we’ll explore how you can easily incorporate plants into your decor to create a serene and rejuvenating environment.</p>

                  <h2>The Beauty of Indoor Plants</h2>

                  <p>Indoor plants not only enhance the visual appeal of your home but also provide numerous benefits, including improved air quality and a sense of well-being. The plant featured in the image above is a perfect example of how a simple addition can bring life and color to any room. Its striking green leaves with variegated patterns add a touch of nature's artistry to your decor.</p>

                  <h2>Choosing the Right Plants</h2>

                  <p>When selecting plants for your indoor space, consider factors such as light availability, maintenance level, and the overall aesthetic you wish to achieve. Plants like the one in the image are ideal for bright, indirect light and require minimal upkeep, making them perfect for busy individuals.</p>

                  <h2>DIY Plant Projects</h2>

                  <h3>Potting and Placement: </h3>
                  <p>Choose stylish pots that complement your interior design. Place the plants in areas where they can thrive and be easily seen, such as on coffee tables, shelves, or window sills.</p>
                  
                  <h3>Decorative Arrangements:</h3>
                  
                  <p>Combine different plants to create a mini indoor garden. Mixing various leaf shapes, sizes, and colors can create a visually appealing arrangement.</p>
                  
                  <h3>Seasonal Themes:</h3>
                  
                  <p>Incorporate seasonal decor with your plants. For spring, consider adding elements like pastel-colored pots or decorative butterflies to enhance the seasonal vibe.</p>

                  <h2>Caring for Your Plants</h2> 

                  <h3>Watering:</h3>
                  <p>Ensure your plants are adequately watered. Over-watering can be as harmful as under-watering, so check the soil moisture regularly.</p>

                  <h3>Light:</h3>
                  <p>Position your plants according to their light requirements. Too much direct sunlight can scorch the leaves, while too little can hinder growth.</p>

                  <h3>Cleaning: </h3>
                  <p>Keep the leaves dust-free by gently wiping them with a damp cloth. This helps the plant photosynthesize more effectively.</p>

                  <p>Adding plants to your home is a simple yet impactful way to celebrate spring. Whether you’re an experienced gardener or a novice, there’s a plant for everyone. Start your spring project today and enjoy the beauty and benefits that indoor greenery brings.</p>
                </article>
              </div>
              <div className="individual-blog-sidebar">
                <h2>Other Blogs: </h2>
                <div className="individual-blog-post">
                  {blogPosts.map((post, index) => (
                    <div key={index} className="individual-blog-pottedpost">
                      <img src={post.img} alt={post.alt} />
                      <div className="individual-blog-post-content-overlay">
                        <p>{post.date}</p>
                        <h2>{post.title}</h2>
                        <Link to="/blog" reloadDocument><button>Read More</button></Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="individual-blog-contact-us">
                  <h3>More information? Contact us today!</h3>
                  <Link to="/contact" reloadDocument><button>Contact Us</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
    </div>
  )
}

export default pageTransition(IndividualBlog);