import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import "./AllServices.css";
import {
  serviceImgOne,
  serviceImgTwo,
  serviceImgThree,
  serviceImgFour,
  serviceImgFive,
  serviceImgSix,
} from "../../assist/index";
import pageTransition from "../../variants/pageTransition";

const serviceInfo = [
  {
    img: serviceImgOne,
    title: "Service Bundle One",
    description:
      "Suspendisse convallis quam eget enim fermentum, vitae suscipit dui convallis. Integer egestas interdum massa. Aliquam ac felis ultricies, vestibulum mauris.",
    path: "/service",
  },
  {
    img: serviceImgTwo,
    title: "Service Bundle Two",
    description:
      "Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget, tincidunt nibh. Pellentesque facilisis luctus nunc vitae egestas.",
    path: "/service",
  },
  {
    img: serviceImgThree,
    title: "Service Bundle Three",
    description:
      "Nullam accumsan felis eu ultrices consectetur. Praesent bibendum laoreet metus ut dapibus. Fusce eu elit eu elit ultrices consequat quis non urna.",
    path: "/service",
  },
  {
    img: serviceImgFour,
    title: "Service Bundle Four",
    description:
      "Vivamus erat nulla, dictum ut ante quis, consectetur malesuada justo. Cras convallis quam at nulla iaculis, eget scelerisque ligula lacinia.",
    path: "/service",
  },
  {
    img: serviceImgFive,
    title: "Service Bundle Five",
    description:
      "Nunc eleifend euismod justo, sit amet luctus risus ullamcorper commodo. Nullam accumsan fringilla maximus. Cras lorem elit, semper id dapibus eget.",
    path: "/service",
  },
  {
    img: serviceImgSix,
    title: "Service Bundle Six",
    description:
      "Aenean suscipit eleifend turpis venenatis lacinia. Suspendisse pellentesque dictum laoreet. Integer euismod, nulla non malesuada tincidunt, dui enim aliquam orci.",
    path: "/service",
  },
];
const AllServices = () => {
  return (
    <div>
      <Helmet>
        <title>Services | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero allservice-hero-image">
          <div className="hero-content">
            <h1>Our services</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="services-grid">
          {serviceInfo.map((service) => (
            <div className="service-card" key={service.title}>
              <Link className="img-link" to={service.path} reloadDocument>
                <img src={service.img} alt={service.title} />
              </Link>
              <h2>{service.title}</h2>
              <p>{service.description}</p>
              <Link className="button" to={service.path} reloadDocument>
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default pageTransition(AllServices);
