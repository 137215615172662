import React, { useState } from 'react';
import './PlantDirectory.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { plantItemOne, plantItemTwo, plantItemThree, plantItemFour, plantItemFive, plantItemSix, plantItemSeven, plantItemEight, plantItemNine } from '../../assist/index';
import pageTransition from '../../variants/pageTransition';

const plantItems = [
  {
    label: "Sold by Cicely",
    img: plantItemOne,
    category: "Plant",
    name: "Assorted Small Succulents",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: plantItemTwo,
    category: "Plant",
    name: "Small Aloe Vera",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: plantItemThree,
    category: "Plant",
    name: "Medium Bonsai",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: plantItemFour,
    category: "Plant",
    name: "Medium Orchid",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: plantItemFive,
    category: "Plant",
    name: "Large White Peace Lily",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: plantItemSix,
    category: "Houseplants",
    name: "Large Monstera (Swiss Cheese) Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: plantItemSeven,
    category: "Houseplants",
    name: "Large Snake Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: plantItemEight,
    category: "Plant",
    name: "Large Cactus",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: plantItemNine,
    category: "Office Plants",
    name: "Office Bundle",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  }
];

const PlantDirectory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [closingDropdown, setClosingDropdown] = useState(null);
  const [checkedState, setCheckedState] = useState({
    "plant-type": [],
    "flower-colour": [],
    "light-requirements": [],
    "shape": [],
    "plant-use": [],
    "bloom-time": [],
    "indoor-outdoor": [],
    "sold-by": []
  });

  const handleClick = (dropdownName) => {
    if (dropdownName === activeDropdown) {
      setClosingDropdown(dropdownName);
      setTimeout(() => {
        setActiveDropdown(null);
        setClosingDropdown(null);
      }, 100); /*Reduced timeout to 300ms for a quicker transition*/
    } else {
      setActiveDropdown(dropdownName);
      setClosingDropdown(null);
    }
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    const sidebar = document.querySelector('.shop-sidebar');
    sidebar.classList.add('closing');
    setTimeout(() => {
      sidebar.classList.remove('closing');
      setIsSidebarOpen(false);
    }, 300); 
  };

  const handleCheckboxChange = (category, value) => {
    setCheckedState((prev) => {
      const currentCategoryState = prev[category];
      if (currentCategoryState.includes(value)) {
        return {
          ...prev,
          [category]: currentCategoryState.filter(item => item !== value)
        };
      } else {
        return {
          ...prev,
          [category]: [...currentCategoryState, value]
        };
      }
    });
  };

  const clearFilters = (category) => {
    setCheckedState((prev) => ({
      ...prev,
      [category]: []
    }));
  };


  return (
    <div>
      <Helmet>
        <title>CICELY | Plant Directory</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="wrapper">
        <div className={`shop-sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={closeSidebar}><IoMdClose/></button>
          <div className="shop-filter-sidebar">
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("plant-type")}>
                Plant Type <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'plant-type' ? 'rotate' : ''}`}/>
              </h4>
              {activeDropdown === 'plant-type' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'plant-type' ? 'open' : closingDropdown === 'plant-type' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="broadleaf" name="plant-type" value="broadleaf" checked={checkedState["plant-type"].includes("broadleaf")} onChange={() => handleCheckboxChange("plant-type", "broadleaf")} />
                    <label htmlFor="broadleaf">Broadleaf Evergreen</label>
                  </div>
                  <div>
                    <input type="checkbox" id="evergreens" name="plant-type" value="evergreens" checked={checkedState["plant-type"].includes("evergreens")} onChange={() => handleCheckboxChange("plant-type", "evergreens")} />
                    <label htmlFor="evergreens">Evergreens</label>
                  </div>
                  <div>
                    <input type="checkbox" id="fruit" name="plant-type" value="fruit" checked={checkedState["plant-type"].includes("fruit")} onChange={() => handleCheckboxChange("plant-type", "fruit")} />
                    <label htmlFor="fruit">Fruit</label>
                  </div>
                  <div>
                    <input type="checkbox" id="perennials" name="plant-type" value="perennials" checked={checkedState["plant-type"].includes("perennials")} onChange={() => handleCheckboxChange("plant-type", "perennials")} />
                    <label htmlFor="perennials">Perennials</label>
                  </div>
                  <div>
                    <input type="checkbox" id="roses" name="plant-type" value="roses" checked={checkedState["plant-type"].includes("roses")} onChange={() => handleCheckboxChange("plant-type", "roses")} />
                    <label htmlFor="roses">Roses</label>
                  </div>
                  <div>
                    <input type="checkbox" id="shrubs" name="plant-type" value="shrubs" checked={checkedState["plant-type"].includes("shrubs")} onChange={() => handleCheckboxChange("plant-type", "shrubs")} />
                    <label htmlFor="shrubs">Shrubs</label>
                  </div>
                  <div>
                    <input type="checkbox" id="trees" name="plant-type" value="trees" checked={checkedState["plant-type"].includes("trees")} onChange={() => handleCheckboxChange("plant-type", "trees")} />
                    <label htmlFor="trees">Trees</label>
                  </div>
                  <button onClick={() => clearFilters("plant-type")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("flower-colour")}>
                Flower Colour <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'flower-colour' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'flower-colour' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'flower-colour' ? 'open' : closingDropdown === 'flower-colour' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="colourful-fruit" name="flower-colour" value="colourful-fruit" checked={checkedState["flower-colour"].includes("colourful-fruit")} onChange={() => handleCheckboxChange("flower-colour", "colourful-fruit")} />
                    <label htmlFor="colourful-fruit">Colourful Fruit</label>
                  </div>
                  <div>
                    <input type="checkbox" id="all-season-foliage" name="flower-colour" value="all-season-foliage" checked={checkedState["flower-colour"].includes("all-season-foliage")} onChange={() => handleCheckboxChange("flower-colour", "all-season-foliage")} />
                    <label htmlFor="all-season-foliage">All Season Foliage</label>
                  </div>
                  <div>
                    <input type="checkbox" id="colourful-twigs" name="flower-colour" value="colourful-twigs" checked={checkedState["flower-colour"].includes("colourful-twigs")} onChange={() => handleCheckboxChange("flower-colour", "colourful-twigs")} />
                    <label htmlFor="colourful-twigs">Colourful Twigs</label>
                  </div>
                  <div>
                    <input type="checkbox" id="foliage-all-season" name="flower-colour" value="foliage-all-season" checked={checkedState["flower-colour"].includes("foliage-all-season")} onChange={() => handleCheckboxChange("flower-colour", "foliage-all-season")} />
                    <label htmlFor="foliage-all-season">Foliage all Season</label>
                  </div>
                  <div>
                    <input type="checkbox" id="blue" name="flower-colour" value="blue" checked={checkedState["flower-colour"].includes("blue")} onChange={() => handleCheckboxChange("flower-colour", "blue")} />
                    <label htmlFor="blue">Blue</label>
                  </div>
                  <div>
                    <input type="checkbox" id="golden-foliage" name="flower-colour" value="golden-foliage" checked={checkedState["flower-colour"].includes("golden-foliage")} onChange={() => handleCheckboxChange("flower-colour", "golden-foliage")} />
                    <label htmlFor="golden-foliage">Golden Foliage</label>
                  </div>
                  <div>
                    <input type="checkbox" id="fall-foliage" name="flower-colour" value="fall-foliage" checked={checkedState["flower-colour"].includes("fall-foliage")} onChange={() => handleCheckboxChange("flower-colour", "fall-foliage")} />
                    <label htmlFor="fall-foliage">Fall Foliage</label>
                  </div>
                  <button onClick={() => clearFilters("flower-colour")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("light-requirements")}>
                Light Requirements <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'light-requirements' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'light-requirements' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'light-requirements' ? 'open' : closingDropdown === 'light-requirements' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="full-sun" name="light-requirements" value="full-sun" checked={checkedState["light-requirements"].includes("full-sun")} onChange={() => handleCheckboxChange("light-requirements", "full-sun")} />
                    <label htmlFor="full-sun">Full Sun</label>
                  </div>
                  <div>
                    <input type="checkbox" id="partial-shade" name="light-requirements" value="partial-shade" checked={checkedState["light-requirements"].includes("partial-shade")} onChange={() => handleCheckboxChange("light-requirements", "partial-shade")} />
                    <label htmlFor="partial-shade">Partial Shade</label>
                  </div>
                  <div>
                    <input type="checkbox" id="full-shade" name="light-requirements" value="full-shade" checked={checkedState["light-requirements"].includes("full-shade")} onChange={() => handleCheckboxChange("light-requirements", "full-shade")} />
                    <label htmlFor="full-shade">Full Shade</label>
                  </div>
                  <button onClick={() => clearFilters("light-requirements")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("shape")}>
                Shape <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'shape' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'shape' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'shape' ? 'open' : closingDropdown === 'shape' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="semi-dwarf" name="shape" value="semi-dwarf" checked={checkedState["shape"].includes("semi-dwarf")} onChange={() => handleCheckboxChange("shape", "semi-dwarf")} />
                    <label htmlFor="semi-dwarf">Semi Dwarf</label>
                  </div>
                  <div>
                    <input type="checkbox" id="upright" name="shape" value="upright" checked={checkedState["shape"].includes("upright")} onChange={() => handleCheckboxChange("shape", "upright")} />
                    <label htmlFor="upright">Upright</label>
                  </div>
                  <div>
                    <input type="checkbox" id="bushy" name="shape" value="bushy" checked={checkedState["shape"].includes("bushy")} onChange={() => handleCheckboxChange("shape", "bushy")} />
                    <label htmlFor="bushy">Bushy</label>
                  </div>
                  <div>
                    <input type="checkbox" id="spreading" name="shape" value="spreading" checked={checkedState["shape"].includes("spreading")} onChange={() => handleCheckboxChange("shape", "spreading")} />
                    <label htmlFor="spreading">Spreading</label>
                  </div>
                  <div>
                    <input type="checkbox" id="globe" name="shape" value="globe" checked={checkedState["shape"].includes("globe")} onChange={() => handleCheckboxChange("shape", "globe")} />
                    <label htmlFor="globe">Globe</label>
                  </div>
                  <div>
                    <input type="checkbox" id="fast-growing" name="shape" value="fast-growing" checked={checkedState["shape"].includes("fast-growing")} onChange={() => handleCheckboxChange("shape", "fast-growing")} />
                    <label htmlFor="fast-growing">Fast growing</label>
                  </div>
                  <div>
                    <input type="checkbox" id="mound" name="shape" value="mound" checked={checkedState["shape"].includes("mound")} onChange={() => handleCheckboxChange("shape", "mound")} />
                    <label htmlFor="mound">Mound</label>
                  </div>
                  <div>
                    <input type="checkbox" id="dwarf" name="shape" value="dwarf" checked={checkedState["shape"].includes("dwarf")} onChange={() => handleCheckboxChange("shape", "dwarf")} />
                    <label htmlFor="dwarf">Dwarf</label>
                  </div>
                  <button onClick={() => clearFilters("shape")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("plant-use")}>
                Plant Use <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'plant-use' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'plant-use' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'plant-use' ? 'open' : closingDropdown === 'plant-use' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="edible" name="plant-use" value="edible" checked={checkedState["plant-use"].includes("edible")} onChange={() => handleCheckboxChange("plant-use", "edible")} />
                    <label htmlFor="edible">Edible</label>
                  </div>
                  <div>
                    <input type="checkbox" id="cascading" name="plant-use" value="cascading" checked={checkedState["plant-use"].includes("cascading")} onChange={() => handleCheckboxChange("plant-use", "cascading")} />
                    <label htmlFor="cascading">Cascading</label>
                  </div>
                  <div>
                    <input type="checkbox" id="low-growing" name="plant-use" value="low-growing" checked={checkedState["plant-use"].includes("low-growing")} onChange={() => handleCheckboxChange("plant-use", "low-growing")} />
                    <label htmlFor="low-growing">Low Growing</label>
                  </div>
                  <div>
                    <input type="checkbox" id="vigorous" name="plant-use" value="vigorous" checked={checkedState["plant-use"].includes("vigorous")} onChange={() => handleCheckboxChange("plant-use", "vigorous")} />
                    <label htmlFor="vigorous">Vigorous</label>
                  </div>
                  <div>
                    <input type="checkbox" id="hardy" name="plant-use" value="hardy" checked={checkedState["plant-use"].includes("hardy")} onChange={() => handleCheckboxChange("plant-use", "hardy")} />
                    <label htmlFor="hardy">Hardy</label>
                  </div>
                  <div>
                    <input type="checkbox" id="ground-cover" name="plant-use" value="ground-cover" checked={checkedState["plant-use"].includes("ground-cover")} onChange={() => handleCheckboxChange("plant-use", "ground-cover")} />
                    <label htmlFor="ground-cover">Ground Cover</label>
                  </div>
                  <div>
                    <input type="checkbox" id="rock-garden" name="plant-use" value="rock-garden" checked={checkedState["plant-use"].includes("rock-garden")} onChange={() => handleCheckboxChange("plant-use", "rock-garden")} />
                    <label htmlFor="rock-garden">Rock Garden</label>
                  </div>
                  <div>
                    <input type="checkbox" id="plant-bed" name="plant-use" value="plant-bed" checked={checkedState["plant-use"].includes("plant-bed")} onChange={() => handleCheckboxChange("plant-use", "plant-bed")} />
                    <label htmlFor="plant-bed">Plant Bed</label>
                  </div>
                  <button onClick={() => clearFilters("plant-use")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("bloom-time")}>
                Bloom Time <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'bloom-time' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'bloom-time' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'bloom-time' ? 'open' : closingDropdown === 'bloom-time' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="spring-blooms" name="bloom-time" value="spring-blooms" checked={checkedState["bloom-time"].includes("spring-blooms")} onChange={() => handleCheckboxChange("bloom-time", "spring-blooms")} />
                    <label htmlFor="spring-blooms">Spring Blooms</label>
                  </div>
                  <div>
                    <input type="checkbox" id="summer-blooms" name="bloom-time" value="summer-blooms" checked={checkedState["bloom-time"].includes("summer-blooms")} onChange={() => handleCheckboxChange("bloom-time", "summer-blooms")} />
                    <label htmlFor="summer-blooms">Summer Blooms</label>
                  </div>
                  <div>
                    <input type="checkbox" id="fall-blooms" name="bloom-time" value="fall-blooms" checked={checkedState["bloom-time"].includes("fall-blooms")} onChange={() => handleCheckboxChange("bloom-time", "fall-blooms")} />
                    <label htmlFor="fall-blooms">Fall Blooms</label>
                  </div>
                  <div>
                    <input type="checkbox" id="winter-blooms" name="bloom-time" value="winter-blooms" checked={checkedState["bloom-time"].includes("winter-blooms")} onChange={() => handleCheckboxChange("bloom-time", "winter-blooms")} />
                    <label htmlFor="winter-blooms">Winter Blooms</label>
                  </div>
                  <div>
                    <input type="checkbox" id="summer-fall-blooms" name="bloom-time" value="summer-fall-blooms" checked={checkedState["bloom-time"].includes("summer-fall-blooms")} onChange={() => handleCheckboxChange("bloom-time", "summer-fall-blooms")} />
                    <label htmlFor="summer-fall-blooms">Summer & Fall Blooms</label>
                  </div>
                  <button onClick={() => clearFilters("bloom-time")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("indoor-outdoor")}>
                Indoor/Outdoor <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'indoor-outdoor' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'indoor-outdoor' && (
                <div className="shop-filter-section-submenu">
                  <div>
                    <input type="checkbox" id="outdoor" name="indoor-outdoor" value="outdoor" checked={checkedState["indoor-outdoor"].includes("outdoor")} onChange={() => handleCheckboxChange("indoor-outdoor", "outdoor")} />
                    <label htmlFor="outdoor">Outdoor</label>
                  </div>
                  <div>
                    <input type="checkbox" id="indoor" name="indoor-outdoor" value="indoor" checked={checkedState["indoor-outdoor"].includes("indoor")} onChange={() => handleCheckboxChange("indoor-outdoor", "indoor")} />
                    <label htmlFor="indoor">Indoor</label>
                  </div>
                  <button onClick={() => clearFilters("indoor-outdoor")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("sold-by")}>
                Sold by Cicely <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'sold-by' ? 'rotate' : ''}`} />
              </h4>
              {activeDropdown === 'sold-by' && (
                <div className={`shop-filter-section-submenu ${activeDropdown === 'sold-by' ? 'open' : closingDropdown === 'sold-by' ? 'closing' : ''}`}>
                  <div>
                    <input type="checkbox" id="sold-by-sheridan" name="sold-by" value="sold-by-sheridan" checked={checkedState["sold-by"].includes("sold-by-sheridan")} onChange={() => handleCheckboxChange("sold-by", "sold-by-sheridan")} />
                    <label htmlFor="sold-by-sheridan">Sold by Cicely</label>
                  </div>
                  <button onClick={() => clearFilters("sold-by")} className="clear-all-button">Clear All</button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="main">
          <div>
            <div className="shop-hero-image">
                <div className="shop-hero">
                  <h1>Plant Directory</h1>
                </div>
            </div> 
            <button className="filter-button" onClick={toggleSidebar}>Filter</button>
            <div className="plant-directory-container">
              <div className="gift">
                <div className="gift-header">
                  <div className="gift-header-content">
                    <p className="plant-intro">Find the perfect plants for your garden, big small. Search by plant name or specify your plant requirements and our handy plant finder will find the perfect match for you!</p>
                  </div>
                </div>
              </div>
              <div className="shop-grid">
                {plantItems.map(plant => (
                  <div className="plant-item" key={plant.id}>
                    {plant.label && <div className="label">{plant.label}</div>}
                    <Link to={plant.path} reloadDocument>
                      <img src={plant.img} alt={plant.name} />
                    </Link>
                    <div className="description">
                      <div>
                        <span className="category"><h3>{plant.category}</h3></span>
                        <Link to={plant.path} reloadDocument>
                          <span className="name"><h4>{(plant.name)}</h4></span>
                        </Link>
                      </div>
                    </div>
                    <div className="meta">
                        <span className="ratings">{plant.ratings}</span>
                        <span className="reviews">{plant.reviews}</span>
                    </div>
                  </div>
                ))}
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shop-bottom-hero-image">
          <div className="shop-bottom-hero">
            <h2>Not sure where to start? We do.</h2>
            <h3>We're here to help.</h3>
            <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
          </div>
        </div>
        <hr/>
    </div>
  )
}

export default pageTransition(PlantDirectory);
