import React, { useState, useEffect } from 'react';
import "./PlantDirectory.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { GrFormPrevious, GrFormNext  } from "react-icons/gr";
import {
  plantItemOne,
  plantItemTwo,
  plantItemThree,
  plantItemFour,
  plantItemFive,
  plantItemSix,
  plantItemSeven,
  plantItemEight,
  plantItemNine,
  plantItemTen,
  plantItemEleven,
  plantItemTwelve,
  plantItemThirteen,
  plantItemFourteen,
  plantItemFifteen,
  plantItemSixteen,
  plantItemSeventeen,
  plantItemEighteen,
  plantItemNinteen,
  plantItemTwenty
} from "../../assist/index";
import pageTransition from "../../variants/pageTransition";

const plantItems = [
  {
    id: 1,
    label: "Sold by Cicely",
    img: plantItemOne,
    category: "Plant",
    name: "Assorted Small Succulents",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor", 
    soldBy: "cicely",
  },
  {
    id: 2,
    label: "Sold by Cicely",
    img: plantItemTwo,
    category: "Plant",
    name: "Small Aloe Vera",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
  },
  {
    id: 3,
    label: "Sold by Cicely",
    img: plantItemThree,
    category: "Plant",
    name: "Medium Bonsai",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "full-sun",
    bloomTime: "spring-blooms",
  },
  {
    id: 4,
    label: "Sold by Cicely",
    img: plantItemFour,
    category: "Plant",
    name: "Medium Orchid",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "partial-shade",
    bloomTime: "summer-blooms",
  },
  {
    id: 5,
    label: "Sold by Cicely",
    img: plantItemFive,
    category: "Plant",
    name: "Large White Peace Lily",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "full-shade",
    bloomTime: "fall-blooms",
  },
  {
    id: 6,
    img: plantItemSix,
    category: "Houseplants",
    name: "Large Monstera (Swiss Cheese) Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown", 
    lightRequirements: "full-shade",
    bloomTime: "winter-blooms",
  },
  {
    id: 7,
    img: plantItemSeven,
    category: "Houseplants",
    name: "Large Snake Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "fun-sun",
    bloomTime: "summer-blooms",
  },
  {
    id: 8,
    img: plantItemEight,
    category: "Plant",
    name: "Large Cactus",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "outdoor",
    soldBy: "unknown",
    lightRequirements: "partial-shade",
    bloomTime: "spring-blooms",
  },
  {
    id: 9,
    img: plantItemNine,
    category: "Officeplants",
    name: "Office Bundle",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "full-shade",
    bloomTime: "summer-fall-blooms",
  },
  {
    id: 10,
    label: "Sold by Cicely",
    img: plantItemNinteen,
    category: "Plant",
    name: "Mimosa Collection",
    ratings: "★★★★☆",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "partial-shade",
    bloomTime: "none",
  },
  {
    id: 11,
    img: plantItemSixteen,
    category: "Houseplants",
    name: "Daffodils",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "indirect-light",
    bloomTime: "summer-blooms",
  },
  {
    id: 12,
    img: plantItemFifteen,
    category: "Plant",
    name: "Croton",
    ratings: "★★★★☆",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "full-sun",
    bloomTime: "winter-summer-blooms",
  },
  {
    id: 13,
    img: plantItemSeventeen,
    category: "Houseplants",
    name: "Haworthia",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "bright-light",
    bloomTime: "winter-blooms",
  },
  {
    id: 14,
    img: plantItemEighteen,
    category: "Houseplants",
    name: "Dwarf Lemon Tree",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "outdoor",
    soldBy: "unknown",
    lightRequirements: "full-sun",
    bloomTime: "spring-summer-blooms",
  },
  {
    id: 15,
    label: "Sold by Cicely",
    img: plantItemFourteen,
    category: "Officeplants",
    name: "Chrysanthemum",
    ratings: "★★★★☆",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "low-light",
    bloomTime: "none",
  },
  {
    id: 16,
    label: "Sold by Cicely",
    img: plantItemTwelve,
    category: "Houseplants",
    name: "Golden Barrel Cactus",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "outdoor",
    soldBy: "cicely",
    lightRequirements: "full-sun",
    bloomTime: "summer-blooms",
  },
  {
    id: 17,
    label: "Sold by Cicely",
    img: plantItemTen,
    category: "Plant",
    name: "Red Anthurium",
    ratings: "★★★★☆",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "cicely",
    lightRequirements: "bright-indirect-light",
    bloomTime: "year-round-blooms",
  },
  {
    id: 18,
    img: plantItemEleven,
    category: "Officeplants",
    name: "Lucky Bamboo",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "low-light",
    bloomTime: "none",
  },
  {
    id: 19,
    img: plantItemThirteen,
    category: "Plants",
    name: "Cherry Blossoms",
    ratings: "★★★★☆",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "outdoor",
    lightRequirements: "full-sun",
    bloomTime: "summer-blooms",
  },
  {
    id: 20,
    img: plantItemTwenty,
    category: "Officeplants",
    name: "Tillandsia Ionantha",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant",
    indoorOutdoor: "indoor",
    soldBy: "unknown",
    lightRequirements: "bright-indirect-light",
    bloomTime: "autumn-blooms",
  }
];


const PlantDirectory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState(plantItems);
  const [activeDropdowns, setActiveDropdowns] = useState([]); 
  const [closingDropdown, setClosingDropdown] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // Set isMounted to true after the component mounts
    setIsMounted(true);
  }, []);
  const [checkedState, setCheckedState] = useState({
    "plant-type": [],
    "light-requirements": [],
    "bloom-time": [],
    "indoor-outdoor": [],
    "sold-by": [],
  });

  const [currentPage, setCurrentPage] = useState(1); 
  
  const itemsPerPage = 6; 
  // Number of items to display per page
  
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage); 
  // Calculates the total number of pages
  
  const paginatedItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage, 
    currentPage * itemsPerPage
  ); 
  // Extracts the items for the current page by slicing the filteredItems array 
  
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber); 
    // Updates the current page when a user navigates to a specific page
  };
  
  const handleClick = (section) => {
    setActiveDropdowns((prev) =>
      prev.includes(section)
        ? prev.filter((item) => item !== section) // Remove if it's already active (closing)
        : [...prev, section] // Add if it's not active (opening)
    );
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    const sidebar = document.querySelector(".shop-sidebar");
    sidebar.classList.add("closing");
    setTimeout(() => {
      sidebar.classList.remove("closing");
      setIsSidebarOpen(false);
    }, 300);
  };

  const handleCheckboxChange = (category, value) => {
    setCheckedState((prev) => {
      const currentCategoryState = prev[category];
      if (currentCategoryState.includes(value)) {
        return {
          ...prev,
          [category]: currentCategoryState.filter((item) => item !== value),
        };
      } else {
        return {
          ...prev,
          [category]: [...currentCategoryState, value],
        };
      }
    });
  };

  const clearFilters = (category) => {
    setCheckedState((prev) => ({
      ...prev,
      [category]: [],
    }));
  };

  /*filter function*/
  const applyFilters = () => {
    let filtered = plantItems;
  
    // Filter by Plant Type
    if (checkedState["plant-type"].length > 0) {
      filtered = filtered.filter((item) =>
        checkedState["plant-type"].includes(item.category.toLowerCase())
      );
    }
  
    // Filter by Indoor/Outdoor
  if (checkedState["indoor-outdoor"].length > 0) {
    filtered = filtered.filter((item) =>
      checkedState["indoor-outdoor"].includes(item.indoorOutdoor.toLowerCase())
    );
  }
  
    // Filter by Sold By
    if (checkedState["sold-by"].length > 0) {
      filtered = filtered.filter((item) =>
        checkedState["sold-by"].some((seller) =>
          item.soldBy?.toLowerCase().includes(seller)
        )
      );
    }
    
  
    // Filter by Light Requirements
    if (checkedState["light-requirements"].length > 0) {
      filtered = filtered.filter((item) =>
        checkedState["light-requirements"].some((light) =>
          item.lightRequirements?.toLowerCase().includes(light)
        )
      );
    }

    // Filter by Bloom Time
    if (checkedState["bloom-time"].length > 0) {
      filtered = filtered.filter((item) =>
        checkedState["bloom-time"].some((bloom) =>
          item.bloomTime?.toLowerCase().includes(bloom)
        )
      );
    }
    setFilteredItems(filtered);

    // Close sidebar modal if screen size is 1024px or smaller
    if (window.innerWidth <= 1024) {
      closeSidebar();
    }

  };

// Function to clear all filters
const clearAllFilters = () => {
  setCheckedState({
    "plant-type": [],
    "light-requirements": [],
    "bloom-time": [],
    "indoor-outdoor": [],
    "sold-by": [],
  });
  setFilteredItems(plantItems); // Reset filtered items to the original list
};



  return (
    <div>
      <Helmet>
        <title>Plant Directory | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div className={`shop-wrapper ${isMounted ? "mounted" : ""}`}>
        <div className={`shop-sidebar ${isSidebarOpen ? "open" : ""}`}>
          <button className="close-button" onClick={closeSidebar}>
            <IoMdClose />
          </button>
          <div className="shop-filter-sidebar">
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("plant-type")}>
                Plant Type{" "}
                <IoIosArrowDown
                  className={`arrow-icon ${
                    activeDropdowns.includes("plant-type") ? "rotate" : ""
                  }`}
                />
              </h4>
                <div
                className={`shop-filter-section-submenu ${
                  activeDropdowns.includes("plant-type") ? "open" : "closed"
                }`}
                data-section="plant-type"              
                >
                  <div>
                    <input
                      type="checkbox"
                      id="plant"
                      name="plant-type"
                      value="plant"
                      checked={checkedState["plant-type"].includes("plant")}
                      onChange={() => handleCheckboxChange("plant-type", "plant")}
                    />
                    <label htmlFor="plant">Plant</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="houseplants"
                      name="plant-type"
                      value="houseplants"
                      checked={checkedState["plant-type"].includes("houseplants")}
                      onChange={() => handleCheckboxChange("plant-type", "houseplants")}
                    />
                    <label htmlFor="houseplants">House plants</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="officeplants"
                      name="plant-type"
                      value="officeplants"
                      checked={checkedState["plant-type"].includes("officeplants")}
                      onChange={() => handleCheckboxChange("plant-type", "officeplants")}
                    />
                    <label htmlFor="officeplants">Office plants</label>
                  </div>                  
                </div>
            </div>
            {}
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("light-requirements")}>
                Light Requirements{" "}
                <IoIosArrowDown
                  className={`arrow-icon ${
                    activeDropdowns.includes("light-requirements") ? "rotate" : ""
                  }`}
                />
              </h4>
                <div
                className={`shop-filter-section-submenu ${
                  activeDropdowns.includes("light-requirements") ? "open" : "closed"
                }`}
                data-section="light-requirements"
                >
                  <div>
                    <input
                      type="checkbox"
                      id="full-sun"
                      name="light-requirements"
                      value="full-sun"
                      checked={checkedState["light-requirements"].includes("full-sun")}
                      onChange={() => handleCheckboxChange("light-requirements", "full-sun")}
                    />
                    <label htmlFor="full-sun">Full Sun</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="partial-shade"
                      name="light-requirements"
                      value="partial-shade"
                      checked={checkedState["light-requirements"].includes("partial-shade")}
                      onChange={() =>
                        handleCheckboxChange("light-requirements", "partial-shade")
                      }
                    />
                    <label htmlFor="partial-shade">Partial Shade</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="full-shade"
                      name="light-requirements"
                      value="full-shade"
                      checked={checkedState["light-requirements"].includes("full-shade")}
                      onChange={() => handleCheckboxChange("light-requirements", "full-shade")}
                    />
                    <label htmlFor="full-shade">Full Shade</label>
                  </div>
                </div>
            </div>
            {}
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("bloom-time")}>
                Bloom Time{" "}
                <IoIosArrowDown
                  className={`arrow-icon ${
                    activeDropdowns.includes("bloom-time") ? "rotate" : ""
                  }`}
                />
              </h4>
                <div
                className={`shop-filter-section-submenu ${
                  activeDropdowns.includes("bloom-time") ? "open" : "closed"
                }`}
                data-section="bloom-time"
                >
                  <div>
                    <input
                      type="checkbox"
                      id="spring-blooms"
                      name="bloom-time"
                      value="spring-blooms"
                      checked={checkedState["bloom-time"].includes("spring-blooms")}
                      onChange={() => handleCheckboxChange("bloom-time", "spring-blooms")}
                    />
                    <label htmlFor="spring-blooms">Spring Blooms</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="summer-blooms"
                      name="bloom-time"
                      value="summer-blooms"
                      checked={checkedState["bloom-time"].includes("summer-blooms")}
                      onChange={() => handleCheckboxChange("bloom-time", "summer-blooms")}
                    />
                    <label htmlFor="summer-blooms">Summer Blooms</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="fall-blooms"
                      name="bloom-time"
                      value="fall-blooms"
                      checked={checkedState["bloom-time"].includes("fall-blooms")}
                      onChange={() => handleCheckboxChange("bloom-time", "fall-blooms")}
                    />
                    <label htmlFor="fall-blooms">Fall Blooms</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="winter-blooms"
                      name="bloom-time"
                      value="winter-blooms"
                      checked={checkedState["bloom-time"].includes("winter-blooms")}
                      onChange={() => handleCheckboxChange("bloom-time", "winter-blooms")}
                    /> 
                    <label htmlFor="winter-blooms">Winter Blooms</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="summer-fall-blooms"
                      name="bloom-time"
                      value="summer-fall-blooms"
                      checked={checkedState["bloom-time"].includes("summer-fall-blooms")}
                      onChange={() =>
                        handleCheckboxChange("bloom-time", "summer-fall-blooms")
                      }
                    />
                    <label htmlFor="summer-fall-blooms">Summer & Fall Blooms</label>
                  </div>
                </div>
            </div>
            {}
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("indoor-outdoor")}>
                Indoor/Outdoor{" "}
                <IoIosArrowDown
                  className={`arrow-icon ${
                    activeDropdowns.includes("indoor-outdoor") ? "rotate" : ""
                  }`}
                />
              </h4>
                <div
                className={`shop-filter-section-submenu ${
                  activeDropdowns.includes("indoor-outdoor") ? "open" : "closed"
                }`}
                data-section="indoor-outdoor"
                >
                  <div>
                    <input
                      type="checkbox"
                      id="outdoor"
                      name="indoor-outdoor"
                      value="outdoor"
                      checked={checkedState["indoor-outdoor"].includes("outdoor")}
                      onChange={() => handleCheckboxChange("indoor-outdoor", "outdoor")}
                    />
                    <label htmlFor="outdoor">Outdoor</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="indoor"
                      name="indoor-outdoor"
                      value="indoor"
                      checked={checkedState["indoor-outdoor"].includes("indoor")}
                      onChange={() => handleCheckboxChange("indoor-outdoor", "indoor")}
                    />
                    <label htmlFor="indoor">Indoor</label>
                  </div>
                </div>
            </div>
            {}
            <div className="shop-filter-section">
              <h4 onClick={() => handleClick("sold-by")}>
                Sold by Cicely{" "}
                <IoIosArrowDown
                  className={`arrow-icon ${
                    activeDropdowns.includes("sold-by") ? "rotate" : ""
                  }`}
                />
              </h4>
                <div
                className={`shop-filter-section-submenu ${
                  activeDropdowns.includes("sold-by") ? "open" : "closed"
                }`}
                data-section="sold-by"
                >
                  <div>
                    <input
                      type="checkbox"
                      id="cicely"
                      name="sold-by"
                      value="cicely"
                      checked={checkedState["sold-by"].includes("cicely")}
                      onChange={() => handleCheckboxChange("sold-by", "cicely")}
                    />
                    <label htmlFor="cicely">Sold by Cicely</label>
                  </div>
                </div>
            </div>
          </div>
          <div className="shop-filter-search">
            <button className="shop-filter-search-button" onClick={applyFilters}>
              search
            </button>
            <a href="#" className="clear-all-link" onClick={(e) => {
                e.preventDefault(); 
                clearAllFilters();  
              }}>
              Clear All
            </a>
          </div>
        </div>
        <div className="main">
          <div>
            <div className="hero shop-hero">
              <div className="hero-content">
                <h1>Plant Directory</h1>
              </div>
            </div>
            <button className="filter-button" onClick={toggleSidebar}>
              Filter
            </button>
            <div className="plant-directory">
            <div className="plant-directory-intro container">
              <p
                  className={`plant-intro ${
                    filteredItems.length === 0 ? "no-match-message" : ""
                  }`}
                >
              {filteredItems.length > 0
                ? "Find the perfect plants for your garden, big or small. Search by plant name or specify your plant requirements and our handy plant finder will find the perfect match for you!"
                : "No results match your current filters. Please adjust the criteria and try again."}
              </p>
            </div>
            <div className="shop-grid container">
            {paginatedItems.map((plant) => (
                 <div className="plant-item" key={plant.id}>
                  {plant.label && <div className="label">{plant.label}</div>}
                  <Link to={plant.path} reloadDocument>
                    <img src={plant.img} alt={plant.name} />
                  </Link>
                  <div className="description">
                    <div>
                      <span className="category">
                        <h3>{plant.category}</h3>
                      </span>
                      <Link to={plant.path} reloadDocument>
                        <span className="name">
                          <h4>{plant.name}</h4>
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="meta">
                    <span className="ratings">{plant.ratings}</span>
                    <span className="reviews">{plant.reviews}</span>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination controls */}
            {totalPages > 1 && (
                <div className="plant-pagination">
                  <div className="pagination-controls">
                    <button className="pagination-previous"
                      disabled={currentPage === 1}
                      onClick={() => goToPage(currentPage - 1)}
                    >
                      <GrFormPrevious />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                      <button
                        key={index}
                        className={currentPage === index + 1 ? "active" : ""}
                        onClick={() => goToPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button  className="pagination-next"
                      disabled={currentPage === totalPages}
                      onClick={() => goToPage(currentPage + 1)}
                    >
                      <GrFormNext />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <section id="promotion">
        <div className="container">
          <div className="shop promo-section">
            <div className="promo-content">
              <h2>Not sure where to start? We do.</h2>
              <h3>We're here to help.</h3>
              <Link to="/contact" reloadDocument>
                <button>Talk to us</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pageTransition(PlantDirectory);
