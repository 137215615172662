import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import "./AllCaseStudies.css";
import {
  allCaseStudiesImgOne,
  allCaseStudiesImgTwo,
  allCaseStudiesImgThree,
  allCaseStudiesImgFour,
  allCaseStudiesImgFive,
  allCaseStudiesImgSix,
} from "../../assist/index";
import pageTransition from "../../variants/pageTransition";

const caseStudies = [
  {
    img: allCaseStudiesImgOne,
    title: "Case Study One",
    path: "/case-study",
  },
  {
    img: allCaseStudiesImgTwo,
    title: "Case Study Two",
    path: "/case-study",
  },
  {
    img: allCaseStudiesImgThree,
    title: "Case Study Three",
    path: "/case-study",
  },
  {
    img: allCaseStudiesImgFour,
    title: "Case Study Four",
    path: "/case-study",
  },
  {
    img: allCaseStudiesImgFive,
    title: "Case Study Five",
    path: "/case-study",
  },
  {
    img: allCaseStudiesImgSix,
    title: "Case Study Six",
    path: "/case-study",
  },
];

const AllCaseStudies = () => {
  /*
    const maxTitleLength = 35; 
    const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
    */
  return (
    <div>
      <Helmet>
        <title>Case Studies | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero allcasestudies-hero">
          <div className="hero-content">
            <h1>Case Studies</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="allcasestudies-grid">
          {caseStudies.map((caseStudies) => (
            <Link
              to={caseStudies.path}
              reloadDocument
              className="allcasestudies-card"
              key={caseStudies.title}
            >
              <img src={caseStudies.img} alt={caseStudies.title} />
              <div className="service-overlay">
                  <h2>{caseStudies.title}</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default pageTransition(AllCaseStudies);
