import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/navbar/Navbar';
import Body from './components/body/Body';
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';
import Blog from './components/blog/Blog';
import About from './components/about/About';
import Legal from './components/legal/Legal';
import Privacy from './components/privacy/Privacy';
import AllServices from './components/allServices/AllServices';
import Service from './components/services/Service';
import AllCaseStudies from './components/allCaseStudies/AllCaseStudies';
import CaseStudy from './components/caseStudies/CaseStudy';
import PlantDirectory from './components/plantDirectory/PlantDirectory';
import IndividualPlant from './components/individualPlant/IndividualPlant';
import IndividualBlog from './components/individualBlog/IndividualBlog';

function App() {
  return (
    <HelmetProvider> 
      <Navbar />
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/individual-blog" element={<IndividualBlog />} />
            <Route path="/plant-directory" element={<PlantDirectory />} />
            <Route path="/individual-plant" element={<IndividualPlant />} />            
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/service" element={<Service />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/all-case-studies" element={<AllCaseStudies />} />
          </Routes>
        </AnimatePresence>
        <Footer />
    </HelmetProvider>
  );
}

export default App;
