// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-content {
    max-width: 100%;
    margin: 0px auto;
    padding: 20px;
}

.privacy-content h1 {
    font-size: 40px;
    color: var(--footer-page-text-color);
    text-align: start;
    margin-bottom: 4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.privacy-content h2 {
    font-size: 18px;
    color: var(--footer-page-text-color);
    margin-bottom: 2rem;
    text-align: start;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.privacy-content p {
    font-size: 18px;
    color: var(--footer-page-text-color);
    margin-bottom: 2rem;
    text-align: start;
    font-family: 'Montserrat', sans-serif;
}

.privacy-content a {
    text-decoration: none;
}


.privacy-content span {
    text-transform: lowerse;
}

@media (max-width: 1200px) {

    .privacy-content h1,
    .privacy-content h2,
    .privacy-content p,
    .privacy-content {
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,oCAAoC;IACpC,iBAAiB;IACjB,mBAAmB;IACnB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,oCAAoC;IACpC,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,oCAAoC;IACpC,mBAAmB;IACnB,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,uBAAuB;AAC3B;;AAEA;;IAEI;;;;QAII,kBAAkB;IACtB;AACJ","sourcesContent":[".privacy-content {\n    max-width: 100%;\n    margin: 0px auto;\n    padding: 20px;\n}\n\n.privacy-content h1 {\n    font-size: 40px;\n    color: var(--footer-page-text-color);\n    text-align: start;\n    margin-bottom: 4rem;\n    font-family: 'Montserrat', sans-serif;\n    font-weight: 600;\n}\n\n.privacy-content h2 {\n    font-size: 18px;\n    color: var(--footer-page-text-color);\n    margin-bottom: 2rem;\n    text-align: start;\n    text-transform: uppercase;\n    font-family: 'Montserrat', sans-serif;\n}\n\n.privacy-content p {\n    font-size: 18px;\n    color: var(--footer-page-text-color);\n    margin-bottom: 2rem;\n    text-align: start;\n    font-family: 'Montserrat', sans-serif;\n}\n\n.privacy-content a {\n    text-decoration: none;\n}\n\n\n.privacy-content span {\n    text-transform: lowerse;\n}\n\n@media (max-width: 1200px) {\n\n    .privacy-content h1,\n    .privacy-content h2,\n    .privacy-content p,\n    .privacy-content {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
