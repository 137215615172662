import React from "react";
import "./Blog.css";
import {
  blogImgOne,
  blogImgTwo,
  blogImgThree,
  blogImgFour,
} from "../../assist/index";
import { Link } from "react-router-dom";
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import { Helmet } from 'react-helmet-async';
import pageTransition from "../../variants/pageTransition";

const blogPosts = [
  {
    img: blogImgOne,
    alt: "Wellness and Your Indoor Air Quality",
    date: "May 17, 2021",
    title: "Wellness and Your Indoor Air Quality",
    link: "/individual-blog",
  },
  {
    img: blogImgTwo,
    alt: "Simple Spring Projects",
    date: "April 05, 2021",
    title: "Simple Spring Projects",
    link: "/individual-blog",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z",
    link: "/individual-blog",
  },
  {
    img: blogImgFour,
    alt: "Pet-Friendly Plants",
    date: "February 2, 2021",
    title: "Pet-Friendly Plants",
    link: "/individual-blog",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z Again",
    link: "/individual-blog",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z Revisited",
    link: "/individual-blog",
  },
];

const Blog = () => {
  return (
    <div>
      <Helmet>
        <title>Blog | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero blog-hero">
          <div className="hero-content">
            <h1>Blog</h1>
          </div>
        </div>
        <div className="container">
          <div id="blog" className="blog-content">
            <h2>News &amp; Insights</h2>
            <div className="blog-posts">
              {blogPosts.map((post, index) => (
                <Link
                  key={index}
                  to={post.link}
                  reloadDocument
                  className="post"
                >
                  <img src={post.img} alt={post.alt} />
                  <div className="content-overlay">
                    <p>{post.date}</p>
                    <h2>{post.title}</h2>
                    <span className="button">
                      Read More
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageTransition(Blog);
