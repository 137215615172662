import React from 'react';
import { IoMdMenu } from "react-icons/io";

const MenuIcon = () => {
  return (
    <IoMdMenu className="menu-icon" />
  );
};

export default MenuIcon;
