import React from "react";
import { Link } from "react-router-dom";
import {
  FaSquareFacebook,
  FaInstagram,
  FaWhatsapp,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaPaypal,
  FaCcJcb,
  FaCcDiscover,
  FaCcDinersClub,
} from "react-icons/fa6";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="site-footer">
      <div className="container">
        <div className="footer-left">
          <div className="footer-links">
            <ul className="footer-nav">
              <li className="footer-upper">
                <Link to="/blog" reloadDocument>
                  Blog
                </Link>
              </li>
              <li className="footer-upper">
                <Link to="/all-services" reloadDocument>
                  Services
                </Link>
              </li>
              <li className="footer-upper">
                <Link to="/plant-directory" reloadDocument>
                  Plant Directory
                </Link>
              </li>
              <li className="footer-upper">
                <Link to="/all-case-studies" reloadDocument>
                  Case Studies
                </Link>
              </li>
            </ul>
            <ul className="footer-nav">
              <li className="footer-second">
                <Link to="/about" reloadDocument>
                  About
                </Link>
              </li>
              <li className="footer-second">
                <Link to="/contact" reloadDocument>
                  Contact
                </Link>
              </li>
              <li className="footer-second">
                <Link to="/legal" reloadDocument>
                  Terms
                </Link> / <Link to="/privacy" reloadDocument>
                  Privacy
                </Link>
              </li>
              <li className="footer-second">
                <a 
                  href="https://wyldcode.com/products/website-templates/optive-bold-react-theme" 
                  target="_blank" 
                >
                  Download Theme
                </a>
              </li>
              
            </ul>
          </div>
          <div className="footer-social-and-rights">
            <div className="footer-socials">
              <Link
                to="https://www.facebook.com/"
                target="_blank"
                reloadDocument
              >
                <FaSquareFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/"
                target="_blank"
                reloadDocument
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://web.whatsapp.com/"
                target="_blank"
                reloadDocument
              >
                <FaWhatsapp />
              </Link>
            </div>
            <p className="copyright">
              &copy; {new Date().getFullYear()} Cicely. All Rights Reserved.
            </p>

            <div className="payment-methods mobile-only-flex">
              <Link to="/" reloadDocument>
                <FaCcVisa />
              </Link>
              <Link to="/" reloadDocument>
                <FaCcMastercard />
              </Link>
              <Link to="/" reloadDocument>
                <FaCcAmex />
              </Link>
              <Link to="/" reloadDocument>
                <FaCcJcb />
              </Link>
              <Link to="/" reloadDocument>
                <FaCcDiscover />
              </Link>
              <Link to="/" reloadDocument>
                <FaCcDinersClub />
              </Link>
              <Link to="/" reloadDocument>
                <FaPaypal />
              </Link>
            </div>
          </div>
          <form className="search-form">
            <input
              type="text"
              aria-label="Subscribe for updates"
              placeholder="Email Address"
            />
            <Link className="button" to="/" reloadDocument>
              Subscribe
            </Link>
          </form>
        </div>
        <div className="footer-right">
          <div className="payment-methods desktop-only-flex">
            <Link to="/" reloadDocument>
              <FaCcVisa />
            </Link>
            <Link to="/" reloadDocument>
              <FaCcMastercard />
            </Link>
            <Link to="/" reloadDocument>
              <FaCcAmex />
            </Link>
            <Link to="/" reloadDocument>
              <FaCcJcb />
            </Link>
            <Link to="/" reloadDocument>
              <FaCcDiscover />
            </Link>
            <Link to="/" reloadDocument>
              <FaCcDinersClub />
            </Link>
            <Link to="/" reloadDocument>
              <FaPaypal />
            </Link>
          </div>
          <Link to="/" reloadDocument>
            <div className="company-name">CICELY</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
