import React from "react";
import "./About.css";
import {
  aboutImgOne,
  aboutImgTwo,
  aboutImgThree,
  aboutImgFour,
} from "../../assist/index";
import { Helmet } from 'react-helmet-async';
import pageTransition from "../../variants/pageTransition";

const aboutFeatures = [
  {
    src: aboutImgOne,
    alt: "Lorem ipsum dolor sit amet",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed nulla metus. Nam efficitur purus purus, ut bibendum sapien commodo sed. Suspendisse potenti. Nam non elementum ex, sed malesuada diam. Cras malesuada turpis ut ante laoreet ultricies.",
  },
  {
    src: aboutImgTwo,
    alt: "Lorem ipsum dolor sit amet",
    text: "Nam id vestibulum arcu, sit amet mattis urna. Suspendisse et sem in nibh gravida lacinia. Cras luctus magna ipsum, molestie malesuada neque suscipit nec. Nulla facilisi. Donec at ullamcorper lorem. Integer neque leo, vestibulum ultrices tellus.",
  },
  {
    src: aboutImgThree,
    alt: "Lorem ipsum dolor sit amet",
    text: "Nulla eu dolor ut quam consequat posuere. Etiam et accumsan metus. Vivamus dolor mauris, fringilla non elementum ut, bibendum a ligula. Quisque maximus leo vitae felis malesuada venenatis. Sed augue eros, ultrices ut orci sit amet.",
  },
  {
    src: aboutImgFour,
    alt: "Lorem ipsum dolor sit amet",
    text: "Aliquam eget dictum turpis. Quisque sit amet ex id risus sodales lobortis non placerat risus. Vestibulum in elit mollis, egestas nulla vel, consectetur mi. Vivamus posuere, orci non commodo tempor, diam metus tristique magna.",
  },
];

const About = () => {
  return (
    <>
      <Helmet>
        <title>Our Story | React Bold Theme</title>
        <meta name="description" content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget." />
      </Helmet>
      <hr />
      <section>
        <div>
          <div className="hero about-hero-image">
            <div className="hero-content about-hero">
              <h1>Our Story</h1>
            </div>
          </div>
        </div>
      </section>
      <section id="features">
        <div className="container">
          {aboutFeatures.map((feature, index) => (
            <div key={index} className="feature about-feature">
              <div className="feature-image about-feature-image">
                <img src={feature.src} alt={feature.alt} />
              </div>
              <div className="feature-text about-feature-text">
                <p>{feature.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default pageTransition(About);
